import { useHistory } from 'react-router-dom'

import { tr, isValidString } from 'mmfintech-commons'
import { paths, useGetMerchantQuery, useOnboardingBannerQry } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { Centered, OnboardingBannerContent, OnboardingBannerWrapper } from './OnboardingBanner.styled'

import { MerchantAccountTypeEnum, OnboardingProviderEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import WarningTriangleIcon from '../../../images/icons/warning-triangle.svg?react'

interface OnboardingBannerProps {
  className?: string
}

export const OnboardingBanner = ({ className }: OnboardingBannerProps) => {
  const { data: merchant } = useGetMerchantQuery()
  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const history = useHistory()

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: () => history.push(paths.onboarding.selectType())
  })

  const Banner = ({ header = undefined, content, buttonCaption = undefined, handleClick = undefined }) => {
    const clickable = typeof handleClick == 'function'

    return (
      <OnboardingBannerWrapper className={className}>
        <Centered className='left'>
          <WarningTriangleIcon />
        </Centered>

        <OnboardingBannerContent>
          {isValidString(header) && <div className='header'>{header}</div>}
          {content}
        </OnboardingBannerContent>

        {clickable && (
          <Centered className='right'>
            <Button
              type='button'
              color='alternative'
              onClick={handleClick}
              data-test='verify-button'
              text={buttonCaption}
            />
          </Centered>
        )}
      </OnboardingBannerWrapper>
    )
  }

  if (onboardingProvider === OnboardingProviderEnum.SUMSUB) {
    if (onboardingStatus === OnboardingStatusEnum.APPROVED_EXPIRING_DOCUMENT) {
      return (
        <Banner
          header={tr('FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.TITLE', 'Expiring document')}
          content={tr(
            'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.CONTENT',
            'Your document is expiring, upload newer one to avoid limitation of services.'
          )}
          buttonCaption={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
          handleClick={() => startOrContinueOnboarding()}
        />
      )
    }

    if (accountType !== MerchantAccountTypeEnum.PROSPECT) {
      if (
        onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
        onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
        onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
      ) {
        return (
          <Banner
            header={tr('FRONTEND.ONBOARDING.RESUME_ALERT_TITLE', 'Complete your account')}
            content={tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_TEXT',
              'Verify to get access to all features and increased payment limits.'
            )}
            buttonCaption={
              onboardingStatus === OnboardingStatusEnum.NOT_STARTED
                ? tr('FRONTEND.ONBOARDING.RESUME_ALERT_GET_STARTED', 'Get started')
                : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')
            }
            handleClick={() => startOrContinueOnboarding()}
          />
        )
      }

      if (
        onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
        onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
      ) {
        return (
          <Banner
            content={tr('FRONTEND.ONBOARDING.RESUME_ALERT_UNDER_REVIEW', 'Your account verification is under review.')}
          />
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
        return (
          <Banner
            content={tr(
              'FRONTEND.ONBOARDING.RESUME_ALERT_REJECTED',
              'Please contact Customer Service to get support on your account verification.'
            )}
          />
        )
      }
    }
  }

  return null
}
