import React from 'react'

import { AlertContent, AlertWrapper, TryAgainButton } from './ErrorFallback.styled'

import { Callback } from 'mmfintech-commons-types'

import ErrorIcon from '../../images/icons/error.svg?react'

interface ErrorFallbackProps {
  resetErrorBoundary: Callback
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ resetErrorBoundary }) => (
  <AlertWrapper>
    <AlertContent>
      <ErrorIcon />
      <p>An unexpected error occurred</p>
      <TryAgainButton type='button' onClick={resetErrorBoundary}>
        Go back to the home page
      </TryAgainButton>
    </AlertContent>
  </AlertWrapper>
)
