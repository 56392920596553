import { Link } from 'react-router-dom'

import cn from 'classnames'

import { tr, useDropDownMenu } from 'mmfintech-commons'
import { getMerchantAccountTypeName, useGetMerchantQuery, useLogoutQry } from 'mmfintech-backend-api'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserMenu.styled'

export const UserMenu = ({ customMenuItem, customLogoutButton, customUserMenuHeader, UserMenuItems }) => {
  const { data: merchant } = useGetMerchantQuery()
  const { name: merchantName } = merchant || {}

  const { logout } = useLogoutQry()
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const LogoutMenuItem = () => (
    <Link to='#' className='menu-item logout' onClick={logout}>
      {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
    </Link>
  )

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className={cn('menu-icon', 'user-menu-icon', { visible })} />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          {customUserMenuHeader && (
            <div className='account-info'>
              <span>{merchantName}</span>
              <span>{getMerchantAccountTypeName(merchant)}</span>
            </div>
          )}

          <UserMenuItems onClick={hideDropDown} className={cn('menu-item', { selector: customMenuItem })} />
          {!customLogoutButton ? (
            <div className='menu-footer'>
              <LogoutMenuItem />
            </div>
          ) : (
            <LogoutMenuItem />
          )}
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
