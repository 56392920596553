import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import thunk from 'redux-thunk'
import { enableMapSet, enableES5 } from 'immer'
import { middlewares, queryReducers } from 'mmfintech-backend-api'
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'

import App from './App'
import { Provider } from 'react-redux'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'mmfintech-portal-commons/dist/main.css'
import './index.css'

enableMapSet()
enableES5()

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: queryReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares.apiMiddleware).concat(thunk),
    ...options
  })

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']

const root = ReactDOM.createRoot(document.getElementById('portal-app') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('portal-app')
// )
