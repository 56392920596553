import { useState } from 'react'

import moment from 'moment'

import { tr, isValidArray, formatMoney } from 'mmfintech-commons'
import {
  periodOptions,
  periodType,
  selectMerchantCurrency,
  useAppSelector,
  useGetAggregatedBalanceChangeQuery
} from 'mmfintech-backend-api'

import { OnboardingBanner } from '../../../onboarding'
import { SelectInput, whiteOutline } from '../../../../components'
import {
  MainPaneButtons,
  MainPaneContainer,
  MainPaneHeader,
  MainPaneWrapper,
  StyledButton
} from '../InvoicesPane/InvoicesPane.styled'

import MoneyInIcon from '../../../../images/icons/money-in-icon.svg?react'
import MoneyOutIcon from '../../../../images/icons/money-out-icon.svg?react'
import NetWorthIcon from '../../../../images/icons/net-worth-icon.svg?react'

export const CashFlowPane = () => {
  const merchantCurrency = useAppSelector(selectMerchantCurrency)

  const [selectedPeriod, setSelectedPeriod] = useState<string>(periodType.MONTH)

  const prepareFilter = () => {
    switch (selectedPeriod) {
      case periodType.WEEK:
        return {
          dateFrom: moment().startOf('week').format('YYYY-MM-DD')
        }

      case periodType.MONTH:
        return {
          dateFrom: moment().startOf('month').format('YYYY-MM-DD')
        }

      default:
        return {
          dateFrom: '2010-01-01',
          dateTo: moment().format('YYYY-MM-DD')
        }
    }
  }

  const { inflowAmount, outflowAmount, balanceChange, currency } = useGetAggregatedBalanceChangeQuery(prepareFilter(), {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => {
      if (isValidArray(data)) {
        const entry = data.find(account => account.currency === merchantCurrency)
        const { inflowAmount, outflowAmount, balanceChange, currency } = entry || data[0]
        return { inflowAmount, outflowAmount, balanceChange, currency }
      }
      return { inflowAmount: 0, outflowAmount: 0, balanceChange: 0, currency: merchantCurrency }
    }
  })

  return (
    <MainPaneWrapper>
      <MainPaneHeader>
        <MainPaneContainer>
          <div className='title'>{tr('FRONTEND.INVOICES.LIST.CASH_FLOW', 'Cash flow')}</div>

          <SelectInput
            id='period'
            name='period'
            className='mr-2 selected-period'
            value={selectedPeriod}
            onChange={(_name: string, value: string) => setSelectedPeriod(value)}
            options={periodOptions()}
            applyStyles={whiteOutline}
            disableSearch={true}
            valueIsObject={true}
            hideErrorLine
            hideLabel
          />
        </MainPaneContainer>

        <OnboardingBanner className='dashboard' />
      </MainPaneHeader>

      <MainPaneButtons className='triple'>
        <StyledButton className='green-shadow static' data-test='money-in-button'>
          <MoneyInIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='money-in-amount'>
              {formatMoney(inflowAmount, currency ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.MONEY_IN', 'Money In')}
          </div>
        </StyledButton>

        <StyledButton className='red-shadow static' data-test='money-out-button'>
          <MoneyOutIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='money-out-amount'>
              {formatMoney(outflowAmount, currency ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.MONEY_OUT', 'Money Out')}
          </div>
        </StyledButton>

        <StyledButton className='magenta-shadow static' data-test='net-worth-button'>
          <NetWorthIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='net-worth-amount'>
              {formatMoney(balanceChange, currency ?? merchantCurrency)}
            </div>
            {tr('FRONTEND.DASHBOARD.NET_WORTH', 'Net worth')}
          </div>
        </StyledButton>
      </MainPaneButtons>
    </MainPaneWrapper>
  )
}
