// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const OnboardingBannerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: default;
  color: white;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;

  border-radius: 1rem;
  box-shadow: none;
  outline: none;
  border: 1px solid #4f4f4f;
  padding: 2.5rem;

  @media (max-width: 565px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    text-align: center;
  }

  @media (max-width: 960px) {
    margin-top: 2rem;
  }

  @media (max-width: 900px) {
    display: flex;
    width: 100%;
  }

  &.dashboard {
    flex: 2;
    width: 100%;
    margin-bottom: 2rem;
    @media (min-width: 1024px) {
      margin-bottom: 0;
      margin-left: 4rem;
    }
  }

  button {
    font-size: 1.6rem;
    height: 1.2rem;
    padding: 2rem 1.5rem;
    white-space: nowrap;
    max-width: 20rem;
  }
`

export const OnboardingBannerContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  margin-top: 1rem;
  :first-of-type {
    margin-top: 0;
  }

  @media (min-width: 565px) {
    margin-top: 0;
  }

  .header {
    font-size: 2rem;
  }
`

export const Centered = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 1em;
  @media (min-width: 566px) {
    width: auto;
    margin-top: 0;

    &.left {
      margin-right: 2rem;
    }
    &.right {
      margin-left: 2rem;
    }
  }
`
