import React from 'react'

import { useGetMerchantQuery } from 'mmfintech-backend-api'
import { tr, translateMonthName, formatMoney } from 'mmfintech-commons'

import { MerchantLogo } from '../../../components'
import { InvoiceCorner } from '../InvoiceEdit'
import {
  InvoicePreviewSection1,
  InvoicePreviewSection2,
  InvoicePreviewSection3,
  InvoicePreviewSection4,
  InvoicePreviewSection5,
  InvoicePreviewWrapper
} from './InvoicePreview.styled'

import { InvoiceLinePreviewResponse } from 'mmfintech-commons-types'

export const InvoicePreview = ({ invoice }) => {
  const { data: merchant } = useGetMerchantQuery()

  const formatDate = (date: any) => {
    if (date) {
      const d = new Date(date)
      const dom = d.getDate()
      const m = translateMonthName(d.getMonth() + 1)
      const y = d.getFullYear()

      return `${dom} ${m.substring(0, 3)}, ${y}`
    }
    return ''
  }

  const {
    payerName,
    date,
    dueDate,
    ownInvoiceNumber,
    currency,
    total,
    subtotal,
    vat,
    lines,
    notes,
    merchantName,
    merchantAddress
  } = invoice || {}

  const { name: merchantName2, address } = merchant || {}
  const { addressLine1: merchantAddress2 } = address || {}

  return (
    <InvoicePreviewWrapper data-test='invoice-preview-modal'>
      <InvoicePreviewSection1>
        <MerchantLogo readonly />

        <div className='merchant-info'>
          <div className='merchant-name'>{merchantName2 || merchantName}</div>
          <div>{merchantAddress2 || merchantAddress}</div>
        </div>
      </InvoicePreviewSection1>

      <InvoicePreviewSection2>
        <div className='title'>{tr('FRONTEND.INVOICES.PREVIEW.TITLE', 'Invoice')}</div>
        <div className='payer'>
          {tr('FRONTEND.INVOICES.PREVIEW.CUSTOMER', 'Invoice for')}
          <b>{payerName}</b>
        </div>
        <div className='thank-you'>
          {tr(
            'FRONTEND.INVOICES.PREVIEW.THANK_YOU',
            'Thank your for your support and do business with us. It was a pleasure work with you guys.'
          )}
        </div>
      </InvoicePreviewSection2>

      <InvoicePreviewSection3>
        <div />
        <div>
          {tr('FRONTEND.INVOICES.PREVIEW.ISSUE_DATE', 'Issued on')}
          <b>{formatDate(date)}</b>
        </div>
        <div>
          {tr('FRONTEND.INVOICES.PREVIEW.DUE_DATE', 'Due on')}
          <b>{formatDate(dueDate)}</b>
        </div>
        <div>
          {tr('FRONTEND.INVOICES.PREVIEW.INVOICE_NUMBER', 'Invoice №')}
          <b>{ownInvoiceNumber}</b>
        </div>
      </InvoicePreviewSection3>

      <InvoicePreviewSection4>
        <table>
          <thead>
            <tr>
              <th className='pr-2'>#</th>
              <th>{tr('FRONTEND.INVOICES.PREVIEW.PRODUCT_SERVICE', 'Product/Service')}</th>
              <th>{tr('FRONTEND.INVOICES.PREVIEW.DESCRIPTION', 'Description')}</th>
              <th className='centered'>{tr('FRONTEND.INVOICES.PREVIEW.QUANTITY', 'Qty')}</th>
              <th className='right-aligned'>{tr('FRONTEND.INVOICES.PREVIEW.PRICE', 'Cost')}</th>
              <th className='centered'>{tr('FRONTEND.INVOICES.PREVIEW.TAX', 'VAT')} (%)</th>
              <th className='right-aligned'>{tr('FRONTEND.INVOICES.PREVIEW.AMOUNT', 'Amount')}</th>
            </tr>
          </thead>

          <tbody>
            {lines?.map((item: InvoiceLinePreviewResponse, index: number) => {
              const { productService, productServiceDescription, quantity, initPrice, vat, lineTotal } = item

              return (
                <React.Fragment key={index}>
                  <tr className='invoice-row' data-test='invoice-row'>
                    <td>
                      <span className='p-column-title'>#</span>
                      <b>{index + 1}</b>
                    </td>
                    <td>
                      <span className='p-column-title'>
                        {tr('FRONTEND.INVOICES.PREVIEW.PRODUCT_SERVICE', 'Product/Service')}
                      </span>
                      <b>{productService}</b>
                    </td>
                    <td>
                      <span className='p-column-title'>
                        {tr('FRONTEND.INVOICES.PREVIEW.DESCRIPTION', 'Description')}
                      </span>
                      <b>{productServiceDescription}</b>
                    </td>
                    <td className='centered'>
                      <span className='p-column-title'>{tr('FRONTEND.INVOICES.PREVIEW.QUANTITY', 'Qty')}</span>
                      <b>{quantity}</b>
                    </td>
                    <td className='right-aligned'>
                      <span className='p-column-title'>{tr('FRONTEND.INVOICES.PREVIEW.PRICE', 'Cost')}</span>
                      <b>{formatMoney(initPrice, currency)}</b>
                    </td>
                    <td className='centered'>
                      <span className='p-column-title'>{tr('FRONTEND.INVOICES.PREVIEW.TAX', 'VAT')} (%)</span>
                      <b>{vat}</b>
                    </td>
                    <td className='right-aligned'>
                      <span className='p-column-title'>{tr('FRONTEND.INVOICES.PREVIEW.AMOUNT', 'Amount')}</span>
                      <b>{formatMoney(lineTotal, currency)}</b>
                    </td>
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </InvoicePreviewSection4>

      <InvoicePreviewSection5>
        <div className='payment-terms'>{notes}</div>

        <div className='invoice-totals'>
          <div className='row'>
            <span>{tr('FRONTEND.INVOICES.PREVIEW.SUBTOTAL', 'Subtotal')}</span>
            <span data-test='value-subtotal'>{formatMoney(subtotal, currency)}</span>
          </div>
          <div className='row'>
            <span>{tr('FRONTEND.INVOICES.PREVIEW.TAX', 'VAT')}</span>
            <span data-test='value-vat'>{formatMoney(vat, currency)}</span>
          </div>
          <div className='row total'>
            <span>{tr('FRONTEND.INVOICES.PREVIEW.TOTAL', 'Total')}</span>
            <span data-test='value-total'>{formatMoney(total, currency)}</span>
          </div>
        </div>
      </InvoicePreviewSection5>

      <InvoiceCorner className='popup' />
    </InvoicePreviewWrapper>
  )
}
