import React from 'react'
import { Link } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths, useLoginQry } from 'mmfintech-backend-api'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { LoginContent, OuterLink } from './Login.styled'
import { CustomInput, GlobalTitle, GlobalWrapper } from '../../../components'

export const Login = () => {
  const { formValues, login, loginError, loginFetching, resetLogin } = useLoginQry()

  const handleSubmit = async (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void login()
  }

  return (
    <GlobalWrapper type={1}>
      <GlobalTitle>{tr('FRONTEND.LOGIN.TITLE', 'Login')}</GlobalTitle>
      <LoginContent noValidate onSubmit={handleSubmit}>
        <CustomInput
          icon='email'
          type='email'
          data-test='email'
          label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
          {...formValues.registerInput('email', resetLogin)}
          disabled={loginFetching}
          hideErrorLine
          autoFocus
        />

        <CustomInput
          icon='lock'
          type='password'
          data-test='password'
          label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
          {...formValues.registerInput('password', resetLogin)}
          disabled={loginFetching}
          autoComplete='off'
          hideErrorLine
        />

        <ErrorDisplay error={loginError} />

        <div className='buttons'>
          <Button
            type='submit'
            color='primary'
            data-test='button-submit'
            text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
            disabled={!formValues.getValue('email')?.length || !formValues.getValue('password')?.length}
            loading={loginFetching}
            iconPosition='right'
            icon='right-arrow'
          />
        </div>

        <div className='inner-link'>
          {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
          <Link to={paths.signUp()} data-test='sign-up'>
            <span>{tr('FRONTEND.LOGIN.SIGN_UP_FREE', 'Sign up for FREE')}</span>
          </Link>
        </div>
      </LoginContent>

      <OuterLink>
        <Link to={paths.forgotPassword()} data-test='forgot-password'>
          {tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
        </Link>
      </OuterLink>
    </GlobalWrapper>
  )
}
