import { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import settings from '../../settings'
import { tr, useWindowSize } from 'mmfintech-commons'
import {
  paths,
  isMerchantProspect,
  isOwnerOrAdministrator,
  useAuth,
  useAppSelector,
  selectCurrentUserRole,
  useGetMerchantQuery,
  useLogoutQry
} from 'mmfintech-backend-api'

import { UserMenu } from './UserMenu'
import { LanguageMenu } from './LanguageMenu'
import {
  BrandLogoWrapper,
  Hamburger,
  HamburgerIcon,
  HamburgerMenu,
  HeaderContainer,
  HeaderInnerContainer,
  HeaderWrapper
} from './Header.styled'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import BrandLogoImg from '../../images/logo/atabank-logo-small-white.svg?react'

const MAX_MOBILE_WIDTH = 1279 //1176

export const Header = () => {
  const [opened, setOpened] = useState(false)

  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: merchant } = useGetMerchantQuery()
  const { apiEnabled, accountType } = merchant || {}

  const [width, height] = useWindowSize()

  const { logout } = useLogoutQry()

  const visibleLanguage = Array.isArray(settings.languages) && settings.languages.length > 1
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const isAccountMenuVisible = () => accountType && isOwnerOrAdministrator(customerRole)
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)
  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH

  const hideMenu = () => opened && setOpened(false)

  const handleLogoutClick = () => {
    void logout()
    return false
  }

  const BrandLogoImage = () => <BrandLogoImg style={{ maxHeight: '32px', width: 'auto' }} />
  const BrandLogo = () => {
    return (
      <BrandLogoWrapper>
        {isLoggedIn() ? (
          <Link to={paths.dashboard()}>
            <BrandLogoImage />
          </Link>
        ) : (
          <a href={settings.landingPageUrl}>
            <BrandLogoImage />
          </a>
        )}
      </BrandLogoWrapper>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={paths.dashboard()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>

      {isAccountMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.banking.accounts.list()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
        </NavLink>
      )}

      <NavLink activeClassName='active-tab' to={paths.banking.transactions.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
      </NavLink>

      {!isMerchantProspect(merchant) ? (
        <NavLink activeClassName='active-tab' to={paths.banking.expenses()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
        </NavLink>
      ) : null}

      <NavLink activeClassName='active-tab' to={paths.invoices.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.INVOICES', 'Invoices')}
      </NavLink>

      {isDevelopersMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.developer()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
    </>
  )

  const UserMenuItems = ({ className = undefined, onClick }) => (
    <>
      <NavLink to={paths.settings()} onClick={onClick} className={className}>
        {tr('FRONTEND.HEADER.SETTINGS', 'Settings')}
      </NavLink>

      {isOwnerOrAdministrator && (
        <NavLink to={paths.userManagement()} onClick={onClick} className={className}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      )}

      <a
        href={settings.customerServiceUrl}
        rel='noopener noreferrer'
        target='_blank'
        onClick={onClick}
        className={className}>
        {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer Service')}
      </a>
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper visible={true}>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {!isHamburgerVisible() && isLoggedIn() && (
          <HeaderInnerContainer>
            <MainMenuItems />
          </HeaderInnerContainer>
        )}

        {isHamburgerVisible() ? (
          (visibleLanguage || isLoggedIn()) && (
            <HeaderInnerContainer>
              <Hamburger>
                <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                  <span className='menu-icon' />
                </HamburgerIcon>
                <HamburgerMenu open={opened} height={height} maxMobileWidth={MAX_MOBILE_WIDTH}>
                  {visibleLanguage && <LanguageMenu hamburger={true} />}
                  {isLoggedIn() && <MainMenuItems />}
                  {isLoggedIn() && <UserMenuItems onClick={hideMenu} />}
                  {isLoggedIn() && (
                    <NavLink to='#' onClick={handleLogoutClick}>
                      {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
                    </NavLink>
                  )}
                  {!isLoggedIn() && (
                    <NavLink to={paths.login()} onClick={hideMenu}>
                      {tr('FRONTEND.HEADER.LOGIN', 'Log in')}
                    </NavLink>
                  )}
                  {!isLoggedIn() && (
                    <NavLink to={paths.signUp()} onClick={hideMenu}>
                      {tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
                    </NavLink>
                  )}
                </HamburgerMenu>
              </Hamburger>
            </HeaderInnerContainer>
          )
        ) : (
          <HeaderInnerContainer>
            {visibleLanguage && <LanguageMenu hamburger={false} />}
            {isLoggedIn() && (
              <UserMenu
                customUserMenuHeader={true}
                customLogoutButton={false}
                customMenuItem={false}
                UserMenuItems={UserMenuItems}
              />
            )}
          </HeaderInnerContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
