import { useState, useEffect, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import theme from './theme'
import settings from './settings'
import styled, { ThemeProvider } from 'styled-components'
import {
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQry,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'

import { withTranslation } from 'react-i18next'
import { CookiesProvider, useCookies } from 'react-cookie'
import { nonProtectedRoutes, protectedRoutes, statusPaths } from './routes'

import Error404 from './views/auth/Error404'

import { Toaster } from 'react-hot-toast'
import { Preloader } from 'mmfintech-portal-commons'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback'
import { GlobalContextProvider, isMobileDevice, isValidObject, OtpContext } from 'mmfintech-commons'
import { ContentWrapper, CookieConsent, Header, ModalDialog, Otp, PrivateRoute, PublicRoute } from './components'

const AppInner = () => {
  useAppInitializerQry(settings.languages)

  const { isAuthFetching } = useAuthQry()

  const [cookies, setCookie] = useCookies(['cookie.consent'])
  const [cookieConsent, setCookieConsent] = useState(null)

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)

  useEffect(() => {
    setCookieConsent(cookies['cookie.consent'])
  }, [cookies])

  useEffect(() => {
    if (typeof queryChallenge === 'object' && queryChallenge.hasOwnProperty('challengeId')) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false,
          hideCloseIcon: true
        },
        content: <Otp />
      })
    } else {
      modalVisible && modalHide()
    }
    // eslint-disable-next-line
  }, [queryChallenge])

  const [modalContent, setModalContent] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)

  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
  }

  const modalShow = ({ options, content }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalVisible(true)
  }

  const globalContext = { modalHide, modalShow }

  return isAuthFetching ? (
    <Preloader />
  ) : (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider value={{ otpOnSuccess, setOtpOnSuccess, otpOnError, setOtpOnError }}>
            <ContentWrapper>
              <Header />
              <Switch>
                {nonProtectedRoutes.map(({ path, component }, index) => (
                  <PublicRoute key={`public-route-${index}`} path={path} exact component={component} />
                ))}
                {protectedRoutes.map(({ path, component, redirect }, index) => (
                  <PrivateRoute
                    key={`private-route-${index}`}
                    path={path}
                    exact
                    redirect={redirect}
                    component={component}
                    invalidSessionRedirect={statusPaths(userStatus)}
                  />
                ))}
                <Route path='*' component={Error404} />
              </Switch>

              {isValidObject(cookieConsent) || isMobileDevice() ? null : <CookieConsent setCookie={setCookie} />}

              <ModalDialog content={modalContent} options={modalOptions} visible={modalVisible} onClose={modalHide} />
            </ContentWrapper>

            <Toaster
              position='top-right'
              containerStyle={{
                top: '10rem',
                zIndex: '10010'
              }}
              toastOptions={{
                className: '',
                style: {
                  color: '#000000',
                  fontFamily: 'inherit',
                  fontSize: '1.4rem',
                  fontStyle: 'normal',
                  padding: '1.5rem',
                  borderRadius: '0'
                },
                success: {}
              }}
            />
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const [logError] = useLazyErrorLoggingQuery()

  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, componentStack) => {
          try {
            const reloadTimestamp = localStorage.getItem('dynamicImportTimestamp')
            if (
              err?.message?.includes('dynamically imported module') &&
              (!reloadTimestamp || Date.now() - Number(reloadTimestamp) > 60_000)
            ) {
              localStorage.setItem('dynamicImportTimestamp', Date.now().toString())
              return window.location.reload()
            }
          } catch (error) {
            // handles cases in which the browser has no access to the storage
            console.error(error)
          }
          return logError({
            level: 'ERROR',
            componentStack: componentStack?.componentStack || componentStack,
            message: err.toString()
          })
        }}
        onReset={() => window.location.replace('/')}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`
